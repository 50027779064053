import { FC, useRef, useState } from 'react'
import { useGlobalContext } from 'context/GlobalContext'
import FlexBox from 'components/FlexBox'
import { Joyride } from '../Joyride/Joyride'
import { Button, Badge, Spinner, Drawer } from '@enterprise-ui/canvas-ui-react'
import { useJoyrideContext } from '../../context/JoyrideContext'
import EnterpriseIcon, {
  BellIcon,
  CampaignIcon,
  ProfileIcon,
} from '@enterprise-ui/icons'
import { NotificationsContent } from './NotificationsContent'
import { useQuery } from 'react-query'
import { getNotifications } from 'services/notifications'
import { useLocation, useNavigate } from 'react-router-dom'
import ProfileDrawer from './ProfileDrawer'

type ProfileMenuProps = {
  enableNotifications: boolean
}

const ProfileMenu: FC<ProfileMenuProps> = ({ enableNotifications }) => {
  const { globalState } = useGlobalContext()
  const { vendorDetails } = globalState
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { joyrideState, joyrideDispatch } = useJoyrideContext()
  const navigate = useNavigate()

  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const vendorIds: number[] =
    globalState.filters.shipmentManagement.vendorIds || []
  const { data: notificationsData, isLoading: isNotificationsLoading } =
    useQuery(
      ['notificationsQueryKey', vendorIds],
      () => getNotifications(vendorIds),
      { enabled: enableNotifications },
    )
  const allNotifications = notificationsData
    ? [
        ...(notificationsData.user_managed_notifications || []),
        ...(notificationsData.system_managed_notifications || []),
      ]
    : []
  const isUrgentNotifications = allNotifications.some(
    (notifications) => notifications.level === 'URGENT',
  )
  const totalNotifications = !notificationsData
    ? 0
    : allNotifications.reduce((acc, notifications) => {
        if (notifications.identifiers.length) {
          return acc + notifications.identifiers.length
        }
        return acc
      }, 0)
  const totalIndicator: string =
    totalNotifications < 100 ? totalNotifications.toString() : '!'

  const location = useLocation()
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(
    location.pathname === '/shipment-management/profile',
  )

  const handleProfileDrawerClose = () => {
    setIsProfileDrawerOpen(false)
    if (location.pathname === '/shipment-management/profile') {
      navigate('/shipment-management')
    }
  }

  return (
    <>
      <FlexBox
        style={{
          background: '#eeeeee',
        }}
        direction="row"
        align="center"
        justify="space-between"
      >
        <div className="hc-pa-xs">
          <strong
            className="hc-mr-sm hc-clr-contrast hc-ws-no-wrap"
            style={{ color: '#222', fontSize: '12px', marginRight: '14px' }}
          >
            {globalState?.userInfo?.firstName} {globalState?.userInfo?.lastName}
          </strong>
          <br />
          <span className="hc-ws-no-wrap hc-clr-contrast hc-fs-min">
            {vendorDetails?.businessPartnerName || 'Business Name Not Found'}
          </span>
        </div>

        <>
          {enableNotifications && (
            <>
              {isNotificationsLoading ? (
                <Spinner size="inline" />
              ) : (
                <Badge
                  className="hc-mr-sm"
                  location="bottom-right"
                  aria-label="number of notifications"
                  content={totalIndicator}
                  color={isUrgentNotifications ? 'error' : 'primary'}
                >
                  <Button
                    data-testid="notification-icon-button"
                    disabled={totalNotifications === 0}
                    iconOnly
                    type="ghost"
                    className={`hc-ml-sm ${
                      isUrgentNotifications && 'animate-wiggle'
                    }`}
                    aria-label="View Notifications"
                    onClick={() => setShowNotifications(!showNotifications)}
                  >
                    <EnterpriseIcon
                      icon={BellIcon}
                      className="hc-clr-interactive"
                    />
                  </Button>
                </Badge>
              )}
            </>
          )}
          <Button
            className="hc-mr-sm hc-ml-sm"
            iconOnly
            type="ghost"
            aria-label={'explore new features'}
            onClick={() => joyrideDispatch({ type: 'JOYRIDE_ALL' })}
          >
            <EnterpriseIcon
              className="hc-clr-interactive"
              icon={CampaignIcon}
              size="lg"
            />
          </Button>
          {joyrideState.showAll && <Joyride />}

          {/*BEGIN PROFILE*/}
          <Button
            className="hc-mr-sm"
            ref={anchorRef}
            style={{ marginRight: '6px' }}
            data-testid="profile-icon-button"
            aria-label={'profile button'}
            type="ghost"
            iconOnly
            onClick={() => {
              setIsProfileDrawerOpen(!isProfileDrawerOpen)
            }}
          >
            <EnterpriseIcon icon={ProfileIcon} />
          </Button>

          {isProfileDrawerOpen && (
            <ProfileDrawer
              isOpen={isProfileDrawerOpen}
              onClose={() => handleProfileDrawerClose()}
            />
          )}
          {/*END PROFILE*/}
        </>
      </FlexBox>
      {showNotifications && (
        <Drawer
          isVisible={showNotifications}
          headingText="Notifications"
          onRequestClose={() => setShowNotifications(false)}
          xs={3}
        >
          <NotificationsContent
            vendorIds={vendorIds}
            userManagedNotifications={
              notificationsData?.user_managed_notifications
            }
            systemManagedNotifications={
              notificationsData?.system_managed_notifications
            }
          />
        </Drawer>
      )}
    </>
  )
}

export default ProfileMenu
