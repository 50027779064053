import { BookingType } from './types'

export type VIEW_TYPES = 'MERCH_VENDOR' | 'CONSOLIDATOR' | 'REVERSE_LOGISTICS'
export enum VIEW_PATHS {
  MERCH_VENDOR = 'merch-vendor',
  CONSOLIDATOR = 'consolidator',
  REVERSE_LOGISTICS = 'reverse-logistics',
}

export enum SHIPMENT_STATUS {
  AWAITING_CARRIER_ACCEPTANCE = 'Awaiting Carrier Acceptance',
  AWAITING_CARRIER_ASSIGNMENT = 'Awaiting Carrier Assignment',
  CANCELLED = 'Cancelled',
  PURCHASE_ORDER_CANCELLED = 'Purchase Order Cancelled',
  CARRIER_ACCEPTED_AWAITING_PICKUP = 'Carrier Accepted, Awaiting Pickup',
  CONTENT_REVIEW_REQUIRED = 'Content Review Required',
  FAILED_MODIFICATION = 'Failed Modification',
  ON_HOLD_FOR_ROUTING = 'On Hold for Routing',
  SENT_FOR_ROUTING_FAILED = 'Sent for Routing Failed',
  NO_STATUS = '',
  PAST_PICKUP = 'Past Pickup',
  PICKED_UP = 'Picked Up',
  PROCESSING_MODIFICATION = 'Processing Modification',
  PROCESSING_CANCEL = 'Processing Cancel',
  RETRIEVING_PICKUP_DATE = 'Routing In Progress',
  SMALL_PACKAGE = 'Small Package',
  STATUS_NOT_FOUND = 'Status Not Found',
}

export enum SERVICE_SHIPMENT_ENUM {
  FAILED = 'FAILED',
  NEW = 'NEW',
  SENT_FOR_ROUTING = 'SENT_FOR_ROUTING',
  SENT_FOR_ROUTING_FAILED = 'SENT_FOR_ROUTING_FAILED',
  CANCEL = 'CANCEL',
  CANCELLED = 'CANCELLED',
  PURCHASE_ORDER_CANCELLED = 'PURCHASE_ORDER_CANCELLED',
  SHIP_SMALL_PARCEL = 'SHIP_SMALL_PARCEL',
  CANCEL_SYNC_FAILED = 'CANCEL_SYNC_FAILED',
  NO_ACTION_YET = 'NO_ACTION_YET',
  TENDER_ACCEPT = 'TENDER_ACCEPT',
  TENDER_REJECT = 'TENDER_REJECT',
  TENDER_CANCEL = 'TENDER_CANCEL',
  PLANNED = 'PLANNED',
  MODIFY = 'MODIFY',
  MOVE_COMPLETE = 'MOVE_COMPLETE',
  MOVE_START = 'MOVE_START',
  PENDING_REVIEW = 'PENDING_REVIEW',
  VENDOR_ACKNOWLEDGED = 'VENDOR_ACKNOWLEDGED',
  REREVIEW_REQUIRED = 'REREVIEW_REQUIRED',
  TENDER = 'TENDER',
}

export enum PRODUCTS_MESSAGE {
  DEFAULT = 'Product Updated',
  PALLET_VOLUME_SET = 'Product Updated: Pallet Volume set back to default (non-stackable max exceeded)',
  PALLETS_STACKABLE_SET = 'Product Updated: Pallets Stackable set to No',
}

export const PO_BOOKING_TYPES: BookingType[] = ['PO', 'PO_MANUAL', 'MANUAL']
