export const VIEWS = {
  SHIPMENT_MANAGEMENT: {
    title: 'Shipment Management',
    path: 'shipment-management',
  },
  TRANSPORTATION_PROFILE: {
    title: 'Transportation Profile',
    path: 'transportation-profile',
  },
  FAQ: { title: 'Frequently Asked Questions', path: '/faq' },
  FEATURE_UPVOTE: {
    title: 'Feature Upvote',
    path: 'featureupvote',
  },
}
