import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { VIEW_PATHS } from 'common/constants'
import { Unauthorized } from 'components/Unauthorized/Unauthorized'
import { lazy, Suspense } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { VIEWS } from './constants'

const MerchVendorShipmentManagement = lazy(
  () => import('components/Routes/MerchVendorShipmentManagement'),
)
const ConsolidatorShipmentManagement = lazy(
  () => import('components/Routes/ConsolidatorShipmentManagement'),
)
const ReverseLogisticsShipmentManagement = lazy(
  () => import('components/Routes/ReverseLogisticsShipmentManagement'),
)

export const ShipmentManagementWrapper = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname
  const { userInfo, internalUserTypeSelectValue } = props
  const {
    isInternal,
    isSet,
    isMerchVendorUser,
    isConsolidatorUser,
    isReverseLogisticsUser,
  } = userInfo

  if (isSet) {
    if (
      isMerchVendorUser ||
      (isInternal && internalUserTypeSelectValue === 'MERCH_VENDOR')
    ) {
      if (
        currentPath !==
        `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.MERCH_VENDOR}`
      ) {
        navigate(
          `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.MERCH_VENDOR}`,
          {
            replace: true,
          },
        )
      }
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <MerchVendorShipmentManagement />
        </Suspense>
      )
    } else if (
      isConsolidatorUser ||
      (isInternal && internalUserTypeSelectValue === 'CONSOLIDATOR')
    ) {
      if (
        currentPath !==
        `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.CONSOLIDATOR}`
      ) {
        navigate(
          `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.CONSOLIDATOR}`,
          {
            replace: true,
          },
        )
      }
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <ConsolidatorShipmentManagement />
        </Suspense>
      )
    } else if (
      isReverseLogisticsUser ||
      (isInternal && internalUserTypeSelectValue === 'REVERSE_LOGISTICS')
    ) {
      if (
        currentPath !==
        `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.REVERSE_LOGISTICS}`
      ) {
        navigate(
          `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.REVERSE_LOGISTICS}`,
          {
            replace: true,
          },
        )
      }
      return (
        <Suspense fallback={<ProgressBar indeterminate />}>
          <ReverseLogisticsShipmentManagement />
        </Suspense>
      )
    } else {
      return <Unauthorized />
    }
  } else {
    return <ProgressBar />
  }
}
