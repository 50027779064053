import { LoadInterface } from 'common/types'
import { getUiServicesEndpoint } from './utils'
import axios from 'axios'

type LoadsSearchResponse = {
  loads: LoadInterface[]
  paginationMetadata: {
    totalItems: number
    totalPages: number
  }
}

export const getLoadsByFilters = async (
  filters: any,
  page = 1,
  per_page = 10,
) => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/search/loads?${new URLSearchParams(filters).toString()}&page=${page}&per_page=${per_page}&key=${key}`

  return axios.get<LoadsSearchResponse>(endpoint)
}
