import React, { createContext, useContext, useReducer } from 'react'

import { mergeShipmentsByPurchaseOrders, updateShipment } from './utils'
import { ShipmentManagementState, ShipmentRowInterface } from '../common/types'
import { purchaseOrderKey } from 'common/utils'

type Action = (
  | { type: 'SET_SHIPMENTS_VIEW_DATA' }
  | { type: 'SET_SELECTED_SHIPMENTS_VIEW_DATA' }
  | { type: 'UPDATE_SHIPMENTS_VIEW_DATA' }
  | { type: 'CLEAR_SHIPMENTS_VIEW_DATA' }
  | { type: 'SET_PURCHASE_ORDERS' }
  | { type: 'SYNC_PURCHASE_ORDERS' }
  | { type: 'UPDATE_PURCHASE_ORDER' }
  | { type: 'SET_SHIPMENTS_BY_PURCHASE_ORDER_KEY' }
  | { type: 'SET_SELECTED_SHIPMENTS_BY_PURCHASE_ORDER_KEY' }
  | { type: 'UPDATE_SHIPMENT' }
  | { type: 'SYNC_SHIPMENTS_BY_PURCHASE_ORDER_KEY' }
  | { type: 'CLEAR_PURCHASE_ORDERS_AND_SHIPMENTS' }
  | { type: 'UPDATE_SHIPMENTS_BY_PURCHASE_ORDER_KEY' }
  | { type: 'SET_LOADS' }
  | { type: 'SET_SHIPMENTS_BY_LOAD_ID_KEY' }
  | { type: 'SET_SELECTED_SHIPMENTS_BY_LOAD_ID_KEY' }
  | { type: 'UPDATE_SHIPMENTS_BY_LOAD_ID_KEY' }
  | { type: 'SYNC_SHIPMENTS_BY_LOAD_ID_KEY' }
  | { type: 'CLEAR_SHIPMENTS_BY_LOAD_ID_KEY' }
) & { payload?: any }

type Dispatch = (action: Action) => void

type ShipmentProviderProps = {
  children: React.ReactNode
  initialState?: ShipmentManagementState
}

const initialState: ShipmentManagementState = {
  shipmentsViewData: {},
  selectedShipmentsIds: [],
  shipmentViewPagination: {},
  purchaseOrders: {},
  loads: {},
  shipmentsByPurchaseOrderKey: {},
  shipmentsByLoadIdKey: {},
  selectedShipmentIdsByPurchaseOrderKey: {},
  selectedShipmentIdsByLoadIdKey: {},
  paginationMetadata: {},
}
const shipmentManagementStateContext = createContext<
  | {
      shipmentManagementState: ShipmentManagementState
      shipmentManagementDispatch: Dispatch
    }
  | undefined
>(undefined)

function shipmentManagementReducer(
  state: ShipmentManagementState,
  action: Action,
) {
  switch (action.type) {
    case 'SET_SHIPMENTS_VIEW_DATA':
      return {
        ...state,
        shipmentsViewData: {
          ...action.payload.shipments,
        },
        shipmentViewPagination: action.payload.paginationMetaData ?? {},
      }
    case 'SET_SELECTED_SHIPMENTS_VIEW_DATA':
      return {
        ...state,
        selectedShipmentsIds: [...action.payload],
      }
    case 'UPDATE_SHIPMENTS_VIEW_DATA': {
      const {
        payload: { shipments },
      } = action

      const shipmentsInState = state.shipmentsViewData

      shipments.forEach((shipment: ShipmentRowInterface) => {
        const currentShipment = state.shipmentsViewData?.[shipment.id]

        shipmentsInState[shipment.id] = updateShipment({
          shipment: currentShipment,
          ...action.payload,
        })
      })

      return {
        ...state,
        shipmentsViewData: { ...shipmentsInState },
      }
    }
    case 'CLEAR_SHIPMENTS_VIEW_DATA': {
      return {
        ...state,
        selectedShipmentsIds: [],
        shipmentsViewData: {},
      }
    }
    case 'SET_PURCHASE_ORDERS': {
      return {
        ...state,
        purchaseOrders: action.payload.purchaseOrders,
        paginationMetadata: action.payload.paginationMetadata,
      }
    }
    case 'UPDATE_PURCHASE_ORDER': {
      const {
        payload: { field, value },
      } = action
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          [purchaseOrderKey(action.payload)]: {
            ...state.purchaseOrders[purchaseOrderKey(action.payload)],
            [field]: value,
          },
        },
      }
    }
    case 'SET_SHIPMENTS_BY_PURCHASE_ORDER_KEY':
      return {
        ...state,
        shipmentsByPurchaseOrderKey: {
          ...state.shipmentsByPurchaseOrderKey,
          ...action.payload,
        },
      }

    case 'SET_SELECTED_SHIPMENTS_BY_PURCHASE_ORDER_KEY':
      return {
        ...state,
        selectedShipmentIdsByPurchaseOrderKey: {
          ...state.selectedShipmentIdsByPurchaseOrderKey,
          [purchaseOrderKey(action.payload)]: action.payload.selectedShipments,
        },
      }
    case 'SYNC_SHIPMENTS_BY_PURCHASE_ORDER_KEY':
      return {
        ...state,
        shipmentsByPurchaseOrderKey: {
          ...state.shipmentsByPurchaseOrderKey,
          ...mergeShipmentsByPurchaseOrders(
            state.shipmentsByPurchaseOrderKey,
            action.payload.data,
            action.payload.checkVersion,
            action.payload.hasRequiredEntitlements,
            action.payload.failedModifiedShipments,
            action.payload.isInternal,
          ),
        },
      }
    case 'UPDATE_SHIPMENT': {
      const {
        payload: { shipmentId, department, purchaseOrderNumber },
      } = action

      let updatedState = { ...state }

      const shipmentFromPOState =
        state?.shipmentsByPurchaseOrderKey?.[
          purchaseOrderKey(action.payload)
        ]?.[shipmentId]

      if (shipmentFromPOState || purchaseOrderNumber) {
        updatedState = {
          ...updatedState,
          shipmentsByPurchaseOrderKey: {
            ...state.shipmentsByPurchaseOrderKey,
            [purchaseOrderKey(action.payload)]: {
              ...state.shipmentsByPurchaseOrderKey[
                purchaseOrderKey(action.payload)
              ],
              [shipmentId]: shipmentFromPOState
                ? updateShipment({
                    shipment: {
                      ...shipmentFromPOState,
                      department,
                    },
                    ...action.payload,
                  })
                : updateShipment({
                    shipment: action.payload,
                    ...action.payload,
                  }),
            },
          },
        }
      }

      const shipmentViewData = state?.shipmentsViewData?.[shipmentId]

      if (shipmentViewData) {
        updatedState = {
          ...updatedState,
          shipmentsViewData: {
            ...state.shipmentsViewData,
            [shipmentId]: updateShipment({
              shipment: { ...state?.shipmentsViewData?.[shipmentId] },
              ...action.payload,
            }),
          },
        }
      }
      return updatedState
    }
    case 'UPDATE_SHIPMENTS_BY_PURCHASE_ORDER_KEY': {
      const {
        payload: { shipments },
      } = action

      const shipmentsForPurchaseOrder =
        state.shipmentsByPurchaseOrderKey[purchaseOrderKey(action.payload)]

      shipments.forEach((shipment: ShipmentRowInterface) => {
        const currentShipment = shipmentsForPurchaseOrder?.[shipment.id]

        shipmentsForPurchaseOrder[shipment.id] = updateShipment({
          shipment: currentShipment,
          ...action.payload,
        })
      })

      return {
        ...state,
        shipmentsByPurchaseOrderKey: {
          ...state.shipmentsByPurchaseOrderKey,
          [purchaseOrderKey(action.payload)]: {
            ...state.shipmentsByPurchaseOrderKey[
              purchaseOrderKey(action.payload)
            ],
            ...shipmentsForPurchaseOrder,
          },
        },
      }
    }
    case 'SET_LOADS':
      return {
        ...state,
        loads: action.payload.loads,
        paginationMetadata: action.payload.paginationMetadata,
      }
    case 'SET_SHIPMENTS_BY_LOAD_ID_KEY':
      return {
        ...state,
        shipmentsByLoadIdKey: {
          ...state.shipmentsByLoadIdKey,
          ...action.payload,
        },
      }
    case 'SET_SELECTED_SHIPMENTS_BY_LOAD_ID_KEY':
      return {
        ...state,
        selectedShipmentIdsByLoadIdKey: {
          ...state.selectedShipmentIdsByLoadIdKey,
          [action.payload.loadId]: action.payload.selectedShipments,
        },
      }
    case 'UPDATE_SHIPMENTS_BY_LOAD_ID_KEY': {
      const {
        payload: { shipments },
      } = action

      const shipmentsForLoad = state.shipmentsByLoadIdKey[action.payload.loadId]

      shipments.forEach((shipment: ShipmentRowInterface) => {
        const currentShipment = shipmentsForLoad?.[shipment.id]

        shipmentsForLoad[shipment.id] = updateShipment({
          shipment: currentShipment,
          ...action.payload,
        })
      })

      return {
        ...state,
        shipmentsByLoadIdKey: {
          ...state.shipmentsByLoadIdKey,
          [action.payload.loadId]: {
            ...state.shipmentsByLoadIdKey[action.payload.loadId],
            ...shipmentsForLoad,
          },
        },
      }
    }
    case 'SYNC_SHIPMENTS_BY_LOAD_ID_KEY':
      return {
        ...state,
        shipmentsByLoadIdKey: {
          ...state.shipmentsByLoadIdKey,
          [action.payload.loadId]: {
            ...state.shipmentsByLoadIdKey[action.payload.loadId],
            ...action.payload.data,
          },
        },
      }
    case 'CLEAR_SHIPMENTS_BY_LOAD_ID_KEY':
      return {
        ...state,
        shipmentsByLoadIdKey: {},
      }

    case 'CLEAR_PURCHASE_ORDERS_AND_SHIPMENTS': {
      return {
        ...state,
        purchaseOrders: {},
        shipmentsByPurchaseOrderKey: {},
      }
    }
    default:
      return state
  }
}

const ShipmentProvider = (props: ShipmentProviderProps) => {
  const [shipmentManagementState, shipmentManagementDispatch] = useReducer(
    shipmentManagementReducer,
    props.initialState || initialState,
  )

  const value = {
    shipmentManagementState,
    shipmentManagementDispatch,
  }

  return (
    <shipmentManagementStateContext.Provider value={value}>
      {props.children}
    </shipmentManagementStateContext.Provider>
  )
}

const useShipmentManagementContext = () => {
  const context = useContext(shipmentManagementStateContext)
  if (context === undefined) {
    throw new Error('useShipment must be used within a ShipmentProvider')
  }
  return context
}

export { ShipmentProvider, useShipmentManagementContext }
