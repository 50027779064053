/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import './animate.css'
import Logo from '../../media/shipiq.svg'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { ShipmentProvider } from 'context/ShipmentManagementContext'
import { TransportationProfileProvider } from 'context/TransportationProfileContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useJoyrideContext } from '../../context/JoyrideContext'
import Session from 'components/Session'
import { VersionBar } from 'components/VersionBar/VersionBar'
import PageNotFound from 'components/Routes/PageNotFound'
import {
  fetchVendors,
  transformVendors,
  vendorsEndpoint,
} from 'services/getVendors'

import {
  fetchBasicLocationsAndVendors,
  basicLocationTransform,
  basicLocationsEndpoint,
} from 'services/getBasicLocationsVendors'
import Modal from 'components/Modal'
import Queries from 'components/Queries'
import { getUserInfo, isNewFeatureVersion } from './utils'
import {
  getParam,
  getShipmentManagementFiltersFromUrl,
  mfeRoutes,
} from 'common/utils'
import mockVendor from 'mocks/mockVendors.json'
import Axios from 'axios'
import { LogLevel, logEvent } from '@praxis/component-logging'
import { useQuery } from 'react-query'
import {
  Grid,
  Layout,
  ProgressBar,
  Button,
  Form,
  Heading,
  Chip,
} from '@enterprise-ui/canvas-ui-react'
import { FAQ } from '../FAQ/FAQ'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import EnterpriseIcon, { CloudCheckIcon } from '@enterprise-ui/icons'
import ProfileMenu from 'components/Main/ProfileMenu'
import { useUpdatesAvailable } from 'UpdatesAvailableContext'
import { VIEWS } from './constants'
import { SideNavBar } from './SideNavBar'
import { LocationHeader } from './LocationHeader'
import { MicroFrontend } from '@praxis/component-microfrontend'
import { LayoutSimple } from 'views/LayoutSimple'
import Cookies from 'js-cookie'
import { UpdateNotificationUserRequest } from '../../common/types'
import { putNotificationUser } from '../../services/notifications'
import { VIEW_TYPES, VIEW_PATHS } from 'common/constants'
import { ShipmentManagementWrapper } from './ShipmentManagementWrapper'

export const Main = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const initViewState: VIEW_TYPES = (() => {
    switch (location.pathname) {
      case `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.MERCH_VENDOR}`:
        return 'MERCH_VENDOR'
      case `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.CONSOLIDATOR}`:
        return 'CONSOLIDATOR'
      case `/${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS.REVERSE_LOGISTICS}`:
        return 'REVERSE_LOGISTICS'
      default:
        return 'MERCH_VENDOR'
    }
  })()
  const [internalUserTypeSelectValue, setInternalUserTypeSelectValue] =
    useState<VIEW_TYPES>(initViewState)

  const { isUpdateAvailable, isDeferred, updateAssets } = useUpdatesAvailable()
  const showUpdatesAvailable = isUpdateAvailable || isDeferred
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  // const [isDarkMode, setIsDarkMode] = useState(false)

  const auth = useAuth()
  const analyticsWrapper = useAnalyticsWrapper()
  const { isAuthorized, login, session } = auth
  const isAuthenticated = isAuthorized()
  const env = useEnv()
  const { globalState, globalDispatch } = useGlobalContext()
  const { userInfo } = globalState
  const { joyrideDispatch } = useJoyrideContext()
  const {
    isExternal,
    isInternal,
    isSet,
    isMerchVendorUser,
    isConsolidatorUser,
    isReverseLogisticsUser,
  } = userInfo

  const TransportationProfile = lazy(
    () => import('components/TransportationProfile'),
  )

  const vendorQuery = useQuery({
    queryKey: ['vendors'],
    queryFn: fetchVendors,
    select: transformVendors,
    refetchOnWindowFocus: false, // default: true
    onSuccess: useCallback(
      (data: any) => {
        if (!isSet) {
          return
        }

        if (isInternal && data) {
          globalDispatch({
            type: 'SET_VENDORS',
            payload: data,
          })
          return
        }

        if (getParam('mockVendor') === true) {
          globalDispatch({
            type: 'SET_VENDORS',
            payload: mockVendor,
          })

          globalDispatch({
            type: 'SET_FILTERS',
            payload: {
              shipmentManagement: getShipmentManagementFiltersFromUrl(
                mockVendor.vendors,
              ),
            },
          })
        }

        if (isExternal && data) {
          globalDispatch({
            type: 'SET_VENDORS',
            payload: data,
          })
          globalDispatch({
            type: 'SET_FILTERS',
            payload: {
              shipmentManagement: getShipmentManagementFiltersFromUrl(
                data.vendors,
              ),
            },
          })
        }
      },
      [isSet, isInternal, isExternal, globalDispatch],
    ),
  })

  const basicLocationsQuery = useQuery({
    queryKey: ['basicLocations'],
    queryFn: fetchBasicLocationsAndVendors,
    select: basicLocationTransform,
    refetchOnWindowFocus: false, // default: true
    onSuccess: useCallback(
      (data: any) => {
        if (!isSet) {
          return
        }
        if (data) {
          globalDispatch({
            type: 'SET_BASIC_LOCS_VENDORS',
            payload: data,
          })
          return
        }
      },
      [isSet, globalDispatch],
    ),
  })

  const makeCallToUpdateUserInfo = () => {
    const createNotificationUserRequest: UpdateNotificationUserRequest = {
      userId: localStorage.getItem('userInfo')?.split(',')[0],
      email: localStorage.getItem('userInfo')?.split(',')[1].trimStart(),
    }

    putNotificationUser(createNotificationUserRequest)
      .then(() => {
        Cookies.set('hasSuccessfullyCalledNotificationUserEndpoint', 'yes')
      })
      .catch(() => {
        Cookies.set('hasSuccessfullyCalledNotificationUserEndpoint', 'no')
      })
  }

  if (vendorQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: vendorsEndpoint,
          error: vendorQuery.error,
          message: 'Failed to fetch vendors',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }

  if (basicLocationsQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: basicLocationsEndpoint,
          error: vendorQuery.error,
          message: 'Failed to fetch basic locations vendors details',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }

  if (!isAuthenticated) {
    login()
  }

  // Once the user is Authenticated, set the ID-Token in the header
  Axios.interceptors.request.use(function (config: any) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'ID-Token': `Bearer ${localStorage?.id_token}`,
      },
    }
  })

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }
    analyticsWrapper?.trackTaskInit({ name: 'Login successful' })
    analyticsWrapper?.trackCustomEvent(
      'login',
      'user',
      JSON.stringify(localStorage.getItem('userInfo')),
      'success',
    )
    globalDispatch({
      type: 'SET_USER_PERMISSIONS',
      payload: getUserInfo(session),
    })

    localStorage.setItem(
      'userInfo',
      `${session?.userInfo?.lanId}, ${session?.userInfo?.email}`,
    )

    localStorage.setItem('userId', `${session?.userInfo?.lanId}`)
    const notificationUserCookie: String =
      Cookies.get('hasSuccessfullyCalledNotificationUserEndpoint') || 'no'

    if (notificationUserCookie === 'no') {
      makeCallToUpdateUserInfo()
    }

    // eslint-disable-next-line
  }, [isAuthenticated])

  if (
    isAuthenticated &&
    isNewFeatureVersion(
      process.env.APP_VERSION,
      localStorage.getItem('prevVersion'),
    )
  ) {
    if (process.env.APP_VERSION) {
      localStorage.setItem('prevVersion', process.env.APP_VERSION)
    }
    joyrideDispatch({ type: 'JOYRIDE_ALL' })
  }

  const showSideNav =
    isMerchVendorUser ||
    (isInternal && internalUserTypeSelectValue === 'MERCH_VENDOR')

  return !isSet ? (
    <ProgressBar indeterminate className="hc-bg-grey07" />
  ) : (
    <Layout darkMode={false} fullWidth theme="default">
      {showSideNav && (
        <SideNavBar
          isSideNavOpen={isSideNavOpen}
          setIsSideNavOpen={setIsSideNavOpen}
        />
      )}

      <Layout.Header
        onClick={() => setIsSideNavOpen(false)}
        includeRail
        className={
          showSideNav
            ? 'hc-pt-none hc-pb-none hc-pl-4x hc-bg-grey07'
            : 'hc-pt-none hc-pb-none hc-pl-xl hc-bg-grey07'
        }
      >
        <Grid.Container justify="space-between" align="center">
          <Grid.Item className="hc-pl-none">
            <Logo style={{ width: '85px' }} />
          </Grid.Item>
          <Grid.Item xs className="hc-ta-center">
            <LocationHeader />
          </Grid.Item>

          <Grid.Item>
            {isInternal ? (
              <Form.Field
                id="userTypeSelect"
                className="hc-mt-md"
                value={internalUserTypeSelectValue}
                type="select"
                onUpdate={(_id: string, value: VIEW_TYPES) => {
                  setInternalUserTypeSelectValue(value)
                  navigate(
                    `${VIEWS.SHIPMENT_MANAGEMENT.path}/${VIEW_PATHS[value]}`,
                    {
                      replace: true,
                    },
                  )
                }}
                options={
                  env?.ENABLE_REVERSE_LOGISTICS
                    ? [
                        { value: 'MERCH_VENDOR', label: 'Merch Vendor View' },
                        { value: 'CONSOLIDATOR', label: 'Consolidator View' },
                        {
                          value: 'REVERSE_LOGISTICS',
                          label: 'Reverse Logistics View',
                        },
                      ]
                    : [
                        { value: 'MERCH_VENDOR', label: 'Merch Vendor View' },
                        { value: 'CONSOLIDATOR', label: 'Consolidator View' },
                      ]
                }
              />
            ) : (
              <Chip size="dense">
                <>
                  {isMerchVendorUser && (
                    <Heading size={6}>Merch Vendor</Heading>
                  )}
                  {isConsolidatorUser && (
                    <Heading size={6}>Consolidator</Heading>
                  )}
                  {isReverseLogisticsUser && (
                    <Heading size={6}>Reverse Logistics</Heading>
                  )}
                </>
              </Chip>
            )}
          </Grid.Item>
          {/* DARK MODE IN DEVELOPMENT - NOT READY FOR PRODUCTION */}
          {/* <Grid.Item>
              <div className="hc-pa-sm hc-ws-no-wrap">
                <Input.Toggle
                  checked={isDarkMode}
                  onChange={() => setIsDarkMode(!isDarkMode)}
                  label={
                    <EnterpriseIcon
                      className="hc-mr-sm"
                      icon={
                        isDarkMode ? LightDarkModeFilledIcon : LightDarkModeIcon
                      }
                    />
                  }
                />
              </div>
            </Grid.Item> */}
          {showUpdatesAvailable && (
            <Grid.Item>
              <Button
                onClick={updateAssets}
                type="primary"
                className="hc-fs-sm hc-ws-no-wrap"
              >
                <EnterpriseIcon
                  icon={CloudCheckIcon}
                  className="hc-mr-sm hc-pt-min"
                />
                Update ShipIQ App
              </Button>
            </Grid.Item>
          )}
          <Grid.Item className="hc-pr-none">
            <ProfileMenu
              enableNotifications={isMerchVendorUser || isInternal}
            />
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <Layout.Body
        includeRail
        className="hc-pa-none hc-bg-contrast-weak"
        onClick={() => setIsSideNavOpen(false)}
      >
        <div className={showSideNav ? 'hc-pl-3x' : 'hc-pl-sm'}>
          <ShipmentProvider>
            <TransportationProfileProvider>
              <Queries />
              <Suspense fallback={<ProgressBar indeterminate />}>
                <Routes>
                  <Route
                    path={`${VIEWS.SHIPMENT_MANAGEMENT.path}/*`}
                    element={
                      <ShipmentManagementWrapper
                        userInfo={userInfo}
                        internalUserTypeSelectValue={
                          internalUserTypeSelectValue
                        }
                      />
                    }
                  />

                  <Route
                    path="lifecycle"
                    element={
                      <ShipmentManagementWrapper
                        userInfo={userInfo}
                        internalUserTypeSelectValue={
                          internalUserTypeSelectValue
                        }
                      />
                    }
                  />

                  <Route
                    path="transportation-profile/:vendorId/:tab"
                    element={<TransportationProfile />}
                  />

                  <Route
                    path="transportation-profile/:vendorId"
                    element={<TransportationProfile />}
                  />

                  <Route
                    path="transportation-profile"
                    element={<TransportationProfile />}
                  />

                  <Route path="faq" element={<FAQ />} />

                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to={`/${VIEWS.SHIPMENT_MANAGEMENT.path}`}
                      />
                    }
                  />

                  {mfeRoutes(env).map((mfe: any) => {
                    return (
                      <Route
                        path={mfe.routePath}
                        element={
                          <LayoutSimple
                            component={
                              <MicroFrontend
                                src={mfe.host}
                                prefix={mfe.prefix}
                                hostUrl={mfe.hostUrl}
                              />
                            }
                            name={mfe.name}
                          />
                        }
                        key={mfe.to}
                      />
                    )
                  })}

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Suspense>

              <Session />
              <Modal />
              <VersionBar />
            </TransportationProfileProvider>
          </ShipmentProvider>
        </div>
      </Layout.Body>
    </Layout>
  )
}
